function getNavData( _this ){

  return [
    {
    name : "随机数" ,
    route : "/random",
    desc: "输入随机范围和生成数量，生成不重复的一组"
  } , {
    name : "抽签" ,
    route : "/ballot",
    desc: "下不了决定的时候，试试用这个工具来抽签吧"
  },{
    name : "BMI计算" ,
    route : "/bmi",
    desc: "算下你的BMI，看你是否标准体重！"
  },{
    name : "实际年龄" , 
    route : "/age" ,
    desc: "突然忘了自己几岁？快速计算你的年龄。"
  },{
    name : "日期倒數" , 
    route : "/countdown" ,
    desc: "快速算一下离那个日子还有几天！"
  },{
    name : "日期计算" , 
    route : "/date-calc" ,
    desc: "给出一个日期，马上告诉你任意天数前后哪一天！"
  },{
    name : "出生天数" , 
    route : "/age-baby" ,
    desc: "输入生日，给一个具体日期，算一下宝宝多大了！"
  },{
    name : "假期查看" , 
    route : "/2023calendar" ,
    desc: "看看今年的假期都是什么时候！"
  },{
    name : "调色盘" , 
    route : "/color" ,
    desc: "选择一个颜色，告诉你颜色代码！"
  }
]
}

function getDIFFTIME (startTime, endTime) {
  let runTime = parseInt((endTime - startTime) / 1000);
  let year = Math.floor(runTime / 86400 / 365);
  runTime = runTime % (86400 * 365);
  let month = Math.floor(runTime / 86400 / 30);
  runTime = runTime % (86400 * 30);
  let day = Math.floor(runTime / 86400);
  runTime = runTime % 86400;
  let hour = Math.floor(runTime / 3600);
  runTime = runTime % 3600;
  let minute = Math.floor(runTime / 60);
  runTime = runTime % 60;
  let second = runTime;
  let result = {
    year,
    month,
    day,
    hour,
    minute,
    second
  };
  console.log(`相差${year}年${month}月${day}天${hour}小时${minute}分${second}秒`);
  return result;
}


function stripscript(s) {
  var pattern = new RegExp("[^a-z^A-Z^0-9]"); 
  var rs = ""; 
  for (var i = 0; i < s.length; i++) {
      rs = rs+s.substr(i, 1).replace(pattern, ''); 
  } 
  return rs;
}



export default {
  getNavData , 
  getDIFFTIME ,
  stripscript , 
}