<template>
  <div class="container">
    <Navbar :title="$t('message.homeTitle')" :iconshow='false'></Navbar>
    <!-- <b-navbar toggleable="lg"  variant="info" fixed="top" >BSTOOLS小工具集合 </b-navbar> -->

    <!-- row的作用是为了对齐 -->
    <div class="row cont">
      <router-link class="col-lg-4 col-md-6 col-sm-12 col-12 item" v-for="(item , index ) in navdata" :key="index" tag="div" :to="item.route">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-4 col-4">
            <div class="icon" :style="{ 'background': 'url(' + require('../assets/'+ item.name +'.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-8 col-8" style="margin-top:5px">
            <div>{{ item.title }}</div>
            <div style="margin-top:3px;font-size:12px;color:#787878">{{item.desc}}</div>
          </div>
        </div>
      </router-link>

     
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar" ;
import common from '../utils/common'


// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
    Navbar
  },
  data () {
    return {
      navdata : [] ,
    }
  },
  mounted () {
    //this.navdata = common.getNavData( this )
    this.navdata = this.$i18n.messages[this.$i18n.locale].getNavData 
  }
};
</script>


<style lang="scss" scoped>
.home {
  // padding: 20px;
}
.cont {
  padding-top: 70px;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.item {
  margin-bottom: 30px;
}
.icon {
  width: 40%;
  min-width: 70px;
  min-height: 70px;
  padding-bottom: 40%;
  // height: 100px;
  margin: 0 auto;
}
</style>