import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView ,
    component: () => import("../views/Videodownload")
  },
  {
    path: '/color',
    name: 'color', 
    component: () => import( '../views/Color')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import( '../views/Test')
  },
  {
    path: '/random' ,
    name: 'random' ,
    component: () => import( '../views/Random')
  },{
    path: '/date-calc' ,
    name: 'dateCalc' ,
    component: () => import( '../views/DateCalc')
  },{
    path: '/age-baby' ,
    name: 'ageBaby' ,
    component: () => import("../views/AgeBaby")
  },{
    path: '/age' , 
    name: 'age' ,
    component: () => import("../views/Age")
  },{
    path: '/countdown' ,
    name: 'countdown' ,
    component: () => import("../views/Countdown")
  },{
    path: '/2023calendar' ,
    name: '2023calendar' ,
    component: () => import("../views/Calendar2023")
  },{
    path: '/bmi' ,
    name: 'bmi' ,
    component: () => import("../views/bmi")
  },{
    path: '/ballot' ,
    name: 'ballot' ,
    component: () => import("../views/Ballot")
  },{
    path: '/videodownload' ,
    name: 'videodownload' ,
    component: () => import("../views/Videodownload")
  },
  {
    path: '/videodownloadpage' ,
    name: 'videodownloadpage' ,
    component: () => import("../views/VideodownloadPage")
  },
  {
    path: '/twittertrending' ,
    name: 'twittertrending' ,
    component: () => import("../views/TwitterKOL")
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if( from.query.lang ){
    to.query.lang = from.query.lang
  }
  next()
})




export default router
